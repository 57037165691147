<template>      
    
      <!-- plate -->
      <div class="plate">

        <!-- row -->
          <div class="row">
            
            <!-- total -->
            <div class="cardbox">
              <div class="toplogo"><svg width="27" height="31" viewBox="0 0 27 31" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.8063 12.9524L13.3988 15.4986L0 7.75934V7.75386L13.4186 0L26.819 7.74582L19.8275 11.7848L13.924 8.37205C13.6925 8.25137 13.4235 8.22472 13.1729 8.29765C12.9223 8.37058 12.7096 8.53747 12.579 8.76352C12.4483 8.98958 12.41 9.25734 12.4719 9.511C12.5338 9.76466 12.6911 9.98464 12.9111 10.1251L17.8063 12.9524Z" fill="#FF59A9"/><path fill-rule="evenodd" clip-rule="evenodd" d="M13.3988 30.9879L13.4187 30.9994L26.8329 23.2433V18.1653L17.8008 12.9486L13.3988 15.4915V20.5688L13.924 20.8721C14.1441 21.0125 14.3014 21.2325 14.3633 21.4862C14.4252 21.7398 14.3868 22.0076 14.2562 22.2337C14.1256 22.4597 13.9128 22.6266 13.6622 22.6995C13.5759 22.7247 13.4873 22.738 13.3988 22.7397V30.9879ZM26.8138 7.74219L19.8221 11.781L26.8329 15.8339V7.75326L26.8138 7.74219Z" fill="#FF59A9"/><path fill-rule="evenodd" clip-rule="evenodd" d="M0 23.2491L13.4186 31.0007L13.4252 30.9969V22.7402C13.2482 22.7482 13.0707 22.7097 12.9111 22.6265L0 15.1684V23.2491ZM13.4252 20.5854L0 12.8326V7.75459L0.0147062 7.74609L13.4252 15.4928V20.5854Z" fill="#FF59A9"/></svg></div>
              <p class="bignum">{{tools.fmk(total,1) }}</p>
              <p class="subtitle">USD {{tools.fm(total,'.')}}</p>
            </div>
            
            <!-- change -->
            <div class="cardbox">
              <p class="bignum" :class="[change < 0 ? 'minus' : 'plus']">{{tools.fmk(change)}}%</p>
              <p class="subtitle">last update 44h</p>
            </div>
          </div>
          
          <!-- row -->
          <div class="row">
            
            <!-- assets -->
            <div class="cardbox">
              <p class="bignum">{{tools.fmk(count,1)}}</p>
              <p class="subtitle">assets</p>
            </div>
            
            <!-- people -->
            <div class="cardbox">
              <p class="bignum">{{tools.fmk(users,1)}}</p>
              <p class="subtitle">people</p>
            </div>
          </div>

          <!-- chart -->
          <div class="row">
            <div class="cardboxfull">
              <!-- <div id="chart">
                <apexchart type="area" height="300" :options="chartOptions" :series="series"></apexchart>
              </div> -->
              <apexchart type="area" height="300" :options="chartOptions" :series="series"></apexchart>

            </div>
          </div>

      </div>
      
    
</template>

<script>

import { defineComponent } from 'vue';

Date.prototype.iso = function () {    
    let year = this.getFullYear()
    let month = this.getMonth() + 1
    let dt = this.getDay()

    if (dt < 10) dt = '0' + dt
    if (month < 10) month = '0' + month
    
    return year+'-' + month + '-'+dt
  }

export default defineComponent({
  data() {
    return {      
      stat:{
        assets: {},
        types: {},
        count: 0,
        users: 0
      }
          
    }
  },
  
  mounted(){
    this.refresh()
  },

  computed: {
    change(){
      if (!this.stat.assets) return 0
      if (!this.stat.assets.change) return 0
      if (!this.stat.assets.change.y) return 0
      return this.stat.assets.change.y.toFixed(2)
    },
    total(){
      if (!this.stat.assets) return 0
      if (!this.stat.assets.points) return 0
      if (!this.stat.assets.points.y) return 0
      let v = this.stat.assets.points.y[0]
      return v.value
    },
    count(){
      if (!this.stat.count) return 0 
      return this.stat.count
    },
    users(){
      if (!this.stat.users) return 0 
      return this.stat.users
    },
    points(){
      if (!this.stat.assets) return []
      if (!this.stat.assets.points) return []
      if (!this.stat.assets.points.y) return []

      let points = this.stat.assets.points.y      
      
      let list = []      
      points.forEach(p => {
        list.push(p.value)
      });
      console.log("points",list)
      return list
    },
    dates(){
      if (!this.stat.assets) return []
      if (!this.stat.assets.points) return []
      if (!this.stat.assets.points.y) return []

      // 2024-06-11
      let list = []
      let points = this.stat.assets.points.y            
      points.forEach(p => {
        let d = new Date(0)
        d.setUTCSeconds(p.unix)
        console.log(d,p.unix)
        list.push(d.iso())
      });
      console.log("dates",list)
      return list
    },

    series(){ 
      return [{
            name: 'Stima',
            data: this.points, 
            color: "#48BA91",           
          }, 
          // {
          //   name: 'series2',
          //   data: [11, 32, 45, 32, 34, 52, 41]
          // }
        ]
    },

    chartOptions(){
      let fm = this.tools.fm
      return {
            chart: { width: '100%', height: 300,type: 'area',toolbar:{show: false},background: 'none'},
            grid:{show: false},            
            dataLabels: {enabled: false},
            legend:{show: false},
            stroke: {curve: 'smooth'},
            theme: {mode: 'dark'},
            xaxis: {
              type: 'datetime',
              categories: this.dates
              // categories: ["2024-06-11","2024-06-12","2024-06-13","2024-06-14","2024-06-15","2024-06-16","2024-06-17","2024-06-18","2024-06-19","2024-06-20","2024-06-21",]
            },
            yaxis:{show: false},
            tooltip: {    
              // enabled: false,    
              followCursor: true,        
              x: {
                // format: 'dd/MM/yy HH:mm'
                format: 'dd MM yyyy'
              },
              y: {
                formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {                  
                  return fm(value, '.')
                }
              }
              // fixed: {
              //     enabled: false,
              //     position: 'topRight',
              //     offsetX: 0,
              //     offsetY: 0,
              // },
            },
          }
    },
  },

  methods:{
    async refresh(){
      let res = await this.api.stats.global()
      if (res.error) {
        return
      }
      this.stat.assets = res.resp.assets
      this.stat.types = res.resp.types
      this.stat.count = res.resp.count
      this.stat.users = res.resp.users
    },


  },
  });


</script>

<style scoped>
  

  .plate{
    position: absolute; 
    z-index: 100; 
    left: 50px; 
    top: 100px; 
    /* width: 10vw; */
    /* height: 80vh; */
    /* background-color: rgba(0, 0, 0, 0.386); */
    /* padding: 20px; */
    /* border-radius: 20px; */
  }
  
  
  .cardbox{
    position: relative; 
    width: 160px;
    height: 160px;
    min-width: 160px;
    min-height: 160px;
    background-color: rgba(255, 255, 255, 0.087);
    padding: 20px;
    border-radius: 20px;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    margin: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .cardboxfull{
    position: relative;    
    width: 100%;
    /* height: 160px; */
    background-color: rgba(255, 255, 255, 0.087);
    /* padding: 20px; */
    border-radius: 20px;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    margin: 6px;
  }

  .blurs{
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
  }

  .bignum{
    position: absolute;
    /* left: 50%; */
    /* top: 50%; */
    left: 50%;transform: translateX(-50%);
    font-size: 42px;
    font-weight: bold;
    color: white;
    letter-spacing: -0.02em;
  }
  
  .bignum.plus{
    font-size: 42px;
    font-weight: 600;
    color: var(--good);
    letter-spacing: -0.02em;
    margin-left: -0px;
  }
  
  .bignum.minus{
    font-size: 42px;
    font-weight: 600;
    color: var(--bad);
    letter-spacing: -0.02em;
    /* margin-left: -0px; */
  }

  .subtitle{
    position: absolute;
    bottom: 10px;
    font-weight: 400;
    font-size: 9px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.6);
  }
  
  
  .toplogo{
    position: absolute;
    top: 10px;    
  }

</style>