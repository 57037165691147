import api from '@/ts/api';
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

export const Dict = defineStore('dict', {
  state: () => ({
    attr: attrStore(), 
    team: teamStore(),    

    types: useStorage('types', []), //attr field type    
    countries: useStorage('country', []), 
    status: useStorage('status', []), 
    currency: useStorage('currency', []), 
    color: useStorage('mycolor', ''), 
    accounts: useStorage('myaccs', {}), //my accounts

    db: [],    
    stats: [],
  }),
  
  getters: {   
    
    year(){
      const d = new Date();
      return d.getFullYear()
    },

    // get all fields for asset category
    form: (state) => (id) => {      
      let attr = this.attr.list(id)
      let fields = this.attr.fields(id)
      if (!fields.length) fields = this.fields
      return fields.push(...attr)
    },

    root: (state) => {
      return {
        all: state.db.filter(item => !item.parent && item.name == 'root').sort((a, b) => a.title.localeCompare(b.title)),
        active: state.db.filter(item => !item.parent && item.name == 'root' && item.active).sort((a, b) => a.title.localeCompare(b.title)),
        inactive: state.db.filter(item => !item.parent && item.name == 'root' && !item.active).sort((a, b) => a.title.localeCompare(b.title)),
      }        
    },
    
    list: (state) => (parent, name, limit) => {
        let c = 0;
        let list = parent ? state.db.filter(item => item.parent == parent && item.name == name) : state.db.filter(item => item.name == name)
        if (limit && list && list.length > limit) list = list.slice(0, 10)
        return list //.sort((a, b) => a.title.localeCompare(b.title)) || []      
    },

    active: (state) => (parent, name, limit) => {
        let c = 0;
        let list = parent ? state.db.filter(item => item.active && item.parent == parent && item.name == name) : state.db.filter(item => item.active && item.name == name)
        if (limit && list && list.length > limit) list = list.slice(0, 10)
        return list//.sort((a, b) => a.title.localeCompare(b.title)) || []      
    },

    inactive: (state) => {      
      return (parent, name, limit) => {
        let c = 0;
        let list = parent ? state.db.filter(item => !item.active && item.parent == parent && item.name == name) : state.db.filter(item => !item.active && item.name == name)
        if (limit && list && list.length > limit) list = list.slice(0, 10)
        return list.sort((a, b) => a.title.localeCompare(b.title)) || []
      }
    },

    // count: (state) => (parent, name) => { state.db.filter(item => item.parent == parent && item.name == name).length },
    count: (state) => (parent, name) => { state.db.filter(item => item.parent == parent && item.name == name).length },

    countActive: (state) => (parent, name) => {parent ? state.db.filter(item => item.active && item.parent == parent && item.name == name).length : state.db.filter(item => item.active && item.name == name).length                },

    // get(id)
    get: (state) => (id) => state.db.find((item) => item.id === id) || {},
    
    // getType('mm')
    getType: (state) => (id) => state.types.find((item) => item.id === id) || {},

    // sid(sid)
    sid: (state) => (sid) => state.db.find((item) => item.sid == sid) || {},

    // brand(cat.id)
    brand: (state) => (id) => state.db.find((item) => item.id == id && item.name == 'brands'),
    
    

    // models(cat.id)
    models: (state) => (id) => state.db.filter(item => item.parent == id && item.name == 'models').sort((a, b) => a.title.localeCompare(b.title)),
    
    // models(cat.id)
    model: (state) => (id) => state.db.find(item => item.id == id && item.name == 'models'),
    
    // submodels(cat.id)
    submodels: (state) => (id) => state.db.filter(item => item.parent == id && item.name == 'submodels'),
    
    // models(cat.id)
    features: (state) => (id) => state.db.filter(item => item.parent == id && item.name == 'features'),
    
    virtualStatus: (state)  => state.status.filter(item => item.virtual),
    realStatus: (state) => state.status.filter(item => !item.virtual).sort((a, b) => a.id - b.id),
    
    statusName: (state) => (id) => {
      let c = state.status.find(item => item.id == id)
      if (!c) return ''
      return  c.title
    },
    countryName: (state) => (id) => {
      let c = state.countries.find(item => item.id == id)
      if (!c) return ''
      return c.title || ''
    },
    
    
    providers(){return {0:'Apple',1:'Google',2:'Email'}},

    dept() {
      return {
        list:['dev','design','market','exe','fin','other'],
        title:{ dev:'Dev', design:'Design', market:'Marketing', exe:'Executive', fin:'Finance', other:'Other'},
      }      
    },


  },
  
  actions: {

    async logout(){
    await this.attr.logout()
    await  this.team.logout()
    this.types = []
    this.countries = []
    this.status = []
    this.currency = []
    this.color = ''
    this.accounts = {}

    },

    async refresh(){

        this.refreshDicts()        
        this.refreshStats()        
        this.refreshStatic()

        this.attr.refresh()
        this.team.refresh()
   },

    async refreshDicts(){
        let res = await api.send('dict/list',{})
        if (res.error) return
        
        this.db = res.resp || []          
   },

    async refreshStatic(){
        let res = await api.send('dict/static',{})
        if (res.error) return
        
        this.countries = res.resp.country || []
        this.status = res.resp.status || []
        this.types = res.resp.types || []   
        this.currency = res.resp.currency || []   
                
   },

   
    async create(parent, name, list){
        let res = await api.send('dict/create',{parent, name, list})
        if (res.error) return res
        this.refresh()   
        return res
    },
      
    async update(id, k, v){
        let res = await api.send('dict/update',{id, k, v})
        if (res.error) return res
        this.refresh()   
        return res
    },

    async updateMeta(id, k, v){
        let res = await api.send('dict/update/meta',{id, k, v})
        if (res.error) return res
        this.refresh()   
        return res
    },

      async delete(id){
        let res = await api.send('dict/delete',{id})
        if (res.error) return res      
        this.refresh()   
        return res
    },

   async refreshStats(){
      let res = await api.send('stats',{})
      if (res.error) return
      this.stats = res.resp || {}
      return {}
  },

  // models(cat.id)
  async brands(asset, limit, offset) {
        let res = await api.send('dict/brands',{asset, limit, offset})
        if (res.error) return res
        return res.resp
    }
     
  },



})



// attr
const attrStore = defineStore('attr', {
  state: () => ({ 
    db: [],
  }),
  getters: {
    
    // get(id)
    get: (state) => (id) => state.db.find((item) => item.id === id) || {},
    
    // field(id, name)
    field: (state) => (id,name) => state.db.find((item) => item.cat == id && item.name == name) || {},
    
    // field(id, name)
    has: (state) => (id,name) => {
      let item = state.db.find((item) => item.cat == id && item.name == name)
      if (item) return true
      return false
    },

    count:(state) => (id) => state.db.filter(item => item.cat == id).length,
    
    // list(cat.id)
    list: (state) => (id) => {
        let list = state.db.filter(item => item.cat == id)        
        return list.sort((a, b) => a.id > b.id)      
    },

    // recurrency dicts list
    dicts: (state) => (id) => {
        let list = state.db.filter(item => item.asset == id && (item.type == 'list' || item.type == 'cat'))        
        return list.sort((a, b) => a.id > b.id)      
    },
    
    
  },

  actions: {
    
      async logout(){
        this.db = []
      },
      // refresh
      async refresh(){
          let res = await api.send('attr/list',{})
          if (res.error) return   
          this.db = res.resp || []
          return {}
      },
      
      // create simple (add attr)
      async create(root, cat, list, active){
          let res = await api.send('attr/create',{root, cat, list, active})
          if (res.error) return res
          this.refresh()
          return res
      },
      
      // create full model {root, name, cat, title...}
      async createModel(model){
          let res = await api.send('attr/create/model',model)
          if (res.error) return res
          this.refresh()   
          return res
      },
      

      // update
      async update(id, k, v){
          let res = await api.send('attr/update',{id, k, v})
          if (res.error) return res
          this.refresh()   
          return res
      },
      
      // update meta
      async updateMeta(id, k, v){
          let res = await api.send('attr/update/meta',{id, k, v})
          if (res.error) return res
          this.refresh()   
          return res
      },

      // updates
      async updates(id, keys){
          let res = await api.send('attr/updates',{id, keys})
          if (res.error) return res
          this.refresh()   
          return res
      },

      // delete
        async delete(id){
          let res = await api.send('attr/delete',{id})
          if (res.error) return res      
          this.refresh()   
          return res
      },
  },


})


// team
const teamStore = defineStore('team', {
  state: () => ({     
    db: useStorage('team', []),
  }),
  getters: {
    
    // get(id)
    get: (state) => (id) => state.db.find((item) => item.id === id) || {},
    
    // list
    list: (state) => state.db,
    
    name: (state) => (id) => {
      let member = state.db.find((item) => item.id === id)
      if (!member) return {}
      return member.name || member.email
    },
    
    
  },

  actions: {

    async logout(){
      this.db = []
    },

      // refresh
      async refresh(){
          let res = await api.send('team/list',{})
          if (res.error) return   
          this.db = res.resp || []
          return {}
      },
      
      // create simple (add attr)
      // async create(cat, sub, list, active){
      //     let res = await api.send('attr/create',{cat, sub, list, active})
      //     if (res.error) return res
      //     this.refresh()
      //     return res
      // },
      
      // create full model {name, cat, title...}
      // async createModel(model){
      //     let res = await api.send('attr/create/model',model)
      //     if (res.error) return res
      //     this.refresh()   
      //     return res
      // },
      

      // update
      // async update(id, k, v){
      //     let res = await api.send('attr/update',{id, k, v})
      //     if (res.error) return res
      //     this.refresh()   
      //     return res
      // },

      // delete
      //   async delete(id){
      //     let res = await api.send('attr/delete',{id})
      //     if (res.error) return res      
      //     this.refresh()   
      //     return res
      // },
  },


})




