<template>
  
    <!-- asset preview -->

    <div class="row " style="width: 80vw; height: 80vh;" v-if="asset">

      <!-- images -->
      <div class="column rborder " style="overflow-y: scroll;">
          <div class="column padding" v-for="(index,item) in asset.images" :key="index">
            <img class="margin" :src="item" style="width: 200px; height: auto; border-radius: 15px;">
            <p class="tag margin">Set cover</p>
          </div>
      </div>
      
      <!-- general -->
      <div class="column w500 rborder left pp" style="overflow-y: scroll;">
          
          <!-- account -->
          <div class="row ch mb6">
              <img v-if="account.image" :src="account.image" alt="">
              <img v-if="!account.image && account.country" :src="`/images/icons/flags/round/${account.country}.svg`"  style="width: 24px;height: 24px; margin-right: 12px">              
              <div class="column">
                <p class="bold" style="line-height: 12px; padding: 0; margin: 0 0 4px" >{{ account.name }}</p>
                <p class="mini" style="line-height: 10px;margin: 0; padding: 0;">{{ account.email }}</p>
              </div>
          </div>
          
          <!-- title -->
          <div class="column">
              <h2>{{ asset.title }}</h2>    
              <p class="desc">{{summary}}</p>
          </div>
          
          <!-- desc -->
          <div class="column mt6">
            <h5>About</h5>
              <p class="desc" >{{ asset.about || 'Nothing yet' }}</p>
          </div>
      </div>
      
      <!-- price -->
      <div class="column rborder left pp w300" style="overflow-y: scroll;">          
        
        
        <Block big hr title="Price" desc="Asset purchase price">
          <p>{{ asset.purchase.curr.toUpperCase() }} {{ tools.fm( asset.purchase.price,',') }} </p> 
          <!-- <li> {{ asset.purchase.city }}, {{ asset.purchase.state || dict.countryName( asset.purchase.country) }}</li> -->
        </Block>
        
        <Block big hr title="GPT" desc="April 2024">
          <p>{{ asset.purchase.curr.toUpperCase() }} {{  asset.purchase.price * 1.012184297 }} </p>          
        </Block>
        
        <Block big hr title="Stima" desc="Asset purchase price">
          <p>STIMA {{ asset.purchase.price * 0.992184297 }} </p>          
        </Block>
          
      </div>
      
      <!-- attr -->
      <div class="column left pp" style="overflow-y: scroll;">
      
        <Block big hr title="Brand" desc="Asset brand and model">
          <div class="column mb2" v-for="(v, k) in asset.brand" :key="k">
            <p v-if="v" class="mini">{{tools.title(k)}}</p>
            <p v-if="v">{{v}}</p>
          </div>
        </Block>

        <Block big hr title="Purchase" desc="Asset location right now">          
          <div class="column mb2" v-for="(v, k) in asset.purchase" :key="k">
            <p v-if="v" class="mini">{{tools.title(k)}}</p>
            <p v-if="v">{{v}}</p>          
          </div>
        </Block>

        <Block big hr title="Location" desc="Asset location right now" >
          <div class="column mb2" v-for="(v, k) in asset.location" :key="k">
            <p v-if="v" class="mini">{{tools.title(k)}}</p>
            <p v-if="v">{{v}}</p>                      
          </div>          
        </Block>
        
        

        <Block big hr title="Attr" desc="Asset location right now">          
          <div class="column mb2" v-for="(v, k) in asset.attr" :key="k">
            <p v-if="v" class="mini">{{tools.title(k)}}</p>
            <p v-if="v">{{v}}</p>          
          </div>
        </Block>

        <!-- <table><tr v-for="(v, k) in asset.location" :key="k" ><td class="bold">{{ tools.title(k) }}</td><td>{{ v }}</td></tr></table> -->
        
        
        <!-- <h4>Purchase</h4>
        <table><tr v-for="(v, k) in asset.purchase" :key="k" ><td class="bold">{{ tools.title(k) }}</td><td>{{ v }}</td></tr></table>
        <br> -->
        
        <!-- <h4>Attributes</h4>
        <table><tr v-for="(v, k) in asset.attr" :key="k" ><td class="bold">{{ tools.title(k) }}</td><td>{{ v }}</td></tr></table>
       -->
      </div>

    </div>
  
    
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  mounted() {  
    this.refresh()
  },
  props:{
    id: Number,
  },
  data() {
    return {                 
      assetItem: undefined,           
      accountItem: undefined,           
    }
  },
  computed: {
      asset(){
        return this.assetItem
      },
      account(){
        return this.accountItem
      },
      summary(){
        if (!this.asset) return 'Summary'
        return this.asset.location.city
      },
  },
  
  methods: {

    uploadAvatar(file){
      console.log(file)
    },
   
    // update user
    async update(k,v){    

      console.log('update',k,v)
      // return
      
      let res = await this.api.send('assets/update',{id: this.item.id,k,v})      
      if (res.error){
        this.error.update = res.error.code + ': '+ res.error.id
        return
      }

      this.refresh()
    },

    async refresh(){      
      let res = await this.api.send('assets/get', {id: this.id})
      console.log(res)
      if (res.error){
        console.log(res.error)        
        return
      }
      this.assetItem = res.resp.asset
      this.accountItem = res.resp.account

    },
    
    


    
  },
});

</script>
<style scoped>

.avatar{
  width: 100px;
  height: 100px;
  border-radius: 200px;
  background: var(--g01);
  border: 0.5px solid var(--g01)
}

.rborder{
  border-right: 0.5px solid var(--g1);
}

.pp{
  padding: 30px 30px 0 ;
}

</style>