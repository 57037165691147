<template>      
    <div class="screen">
      <div class="logos">
        <svg width="119" height="38" viewBox="0 0 119 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4228 15.8772L16.8726 18.9983L0 9.51145V9.50473L16.8976 0L33.772 9.49488L24.968 14.4459L17.5339 10.2625C17.2425 10.1146 16.9037 10.0819 16.5881 10.1713C16.2726 10.2607 16.0046 10.4653 15.8402 10.7424C15.6757 11.0195 15.6274 11.3477 15.7053 11.6586C15.7832 11.9696 15.9814 12.2392 16.2584 12.4114L22.4228 15.8772Z" fill="#FF59A9"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8726 37.9859L16.8976 38L33.7896 28.4926V22.268L22.4159 15.8733L16.8726 18.9903V25.2142L17.534 25.586C17.811 25.7581 18.0092 26.0277 18.0871 26.3387C18.165 26.6496 18.1167 26.9779 17.9522 27.2549C17.7878 27.532 17.5198 27.7366 17.2043 27.826C17.0955 27.8568 16.984 27.8732 16.8726 27.8752V37.9859ZM33.7655 9.49121L24.9611 14.442L33.7896 19.4101V9.50478L33.7655 9.49121Z" fill="#FF59A9"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 28.4978L16.8976 37.9998L16.9059 37.9951V27.874C16.683 27.8838 16.4594 27.8366 16.2584 27.7346L0 18.5925V28.4978ZM16.9059 25.2327L0 15.7292V9.50456L0.0185173 9.49414L16.9059 18.9901V25.2327Z" fill="#FF59A9"/>
          <path d="M104.551 25.9995L109.699 11.2031H112.375V14.1255H111.452L107.802 25.9995H104.551ZM107.31 22.4106L108.068 20.0933H114.928L115.687 22.4106H107.31ZM115.236 25.9995L111.585 14.1255V11.2031H113.339L118.486 25.9995H115.236Z" fill="#E1E1E1"/>
          <path d="M86.8838 25.9995V11.2031H90.4419L94.5742 21.9595H94.7075L98.8398 11.2031H102.398V25.9995H99.6396V15.9507H98.1528L101.157 11.6133L95.6099 25.9995H93.6719L88.1245 11.6133L91.1289 15.9507H89.6421V25.9995H86.8838Z" fill="#E1E1E1"/>
          <path d="M80.6289 25.9995V11.2031H83.7256V25.9995H80.6289Z" fill="#E1E1E1"/>
          <path d="M70.8057 25.9995V13.7563H66.4272V11.2031H78.2808V13.7563H73.9023V25.9995H70.8057Z" fill="#E1E1E1"/>
          <path d="M58.9111 26.3276C57.7354 26.3276 56.7134 26.1533 55.8452 25.8047C54.9771 25.4561 54.2935 24.9604 53.7944 24.3179C53.2954 23.6685 53.0186 22.8994 52.9639 22.0107L52.9536 21.8467H55.917L55.9272 21.9492C55.9751 22.3252 56.1323 22.6533 56.3989 22.9336C56.6724 23.207 57.0278 23.4224 57.4653 23.5796C57.9097 23.7368 58.4189 23.8154 58.9932 23.8154C59.5537 23.8154 60.0425 23.7368 60.4595 23.5796C60.8765 23.4155 61.2012 23.1899 61.4336 22.9028C61.666 22.6089 61.7822 22.2739 61.7822 21.8979V21.8877C61.7822 21.4023 61.584 21.0161 61.1875 20.729C60.7979 20.4351 60.1416 20.1924 59.2188 20.001L57.6602 19.6831C56.1562 19.3823 55.0454 18.8765 54.3276 18.1655C53.6167 17.4546 53.2612 16.5386 53.2612 15.4175V15.4072C53.2612 14.498 53.5039 13.7051 53.9893 13.0283C54.4746 12.3447 55.1411 11.8149 55.9888 11.439C56.8364 11.063 57.8071 10.875 58.9009 10.875C60.0493 10.875 61.0337 11.0596 61.854 11.4287C62.6812 11.791 63.3271 12.2969 63.792 12.9463C64.2568 13.5889 64.52 14.3306 64.5815 15.1714L64.5918 15.3252H61.6694L61.6489 15.2021C61.5942 14.833 61.4473 14.5151 61.208 14.2485C60.9688 13.9751 60.6509 13.7632 60.2544 13.6128C59.8647 13.4624 59.4136 13.3872 58.9009 13.3872C58.3882 13.3872 57.9404 13.4624 57.5576 13.6128C57.1748 13.7632 56.8774 13.9751 56.6655 14.2485C56.4604 14.5151 56.3579 14.8296 56.3579 15.1919V15.2021C56.3579 15.6807 56.5527 16.0669 56.9424 16.3608C57.332 16.6548 57.9575 16.8906 58.8188 17.0684L60.3877 17.3862C61.4268 17.5981 62.2744 17.8853 62.9307 18.2476C63.5938 18.6099 64.0825 19.0645 64.397 19.6113C64.7183 20.1582 64.8789 20.8179 64.8789 21.5903V21.6006C64.8789 22.5645 64.6396 23.4019 64.1611 24.1128C63.6826 24.8169 62.9956 25.3638 62.1001 25.7534C61.2114 26.1362 60.1484 26.3276 58.9111 26.3276Z" fill="#E1E1E1"/>
        </svg>
    </div>

      <div style="z-index: 1;" id="globeViz"></div>

      
      <p @click="addnew()" class="button" style="z-index: 1000; position: absolute; bottom: 30px; right: 30px;">Add new</p>
      
      <!-- stats -->
      <Stats/>
      
      
      <!-- plate -->
      <div class="slider">
        
        <div class="sliderbox w300">        
          
          <div class="card row w400 padding" v-for="(item, index) in items" :key="index">          
            <!-- <div :style="{backgroundImage: `url(${item.image})`}" style="width: 200px; height: 100px; background-size: cover;"></div> -->
            <img :src="item.image" style="max-width: 200px; border-radius: 25px;">
            <div class="column padding">
              <h4>{{ item.title }}</h4>          
              <p class="desc">USD {{ tools.format(item.usd) }}</p>          
              <p class="desc">STIMA {{ tools.format(item.stima) }}</p>          
            </div>
          </div>      
        </div>
      </div>

      <!-- create attr -->
      <Transition name="fader">
        <div class="popupAsset" v-if="appendNew">
          <div class="row w600" style="position: relative; width: fit-content;">   
                
                <img :src="current.image" style="max-height: 300px; border-radius: 25px;">
                <div class="column padding w200">
                  <h3>{{ current.title }}</h3>
                  <p class="desc">USD {{ tools.format(current.usd, '.')  }}</p>
                  <p class="desc">STIMA {{ tools.format(current.stima, '.') }}</p>
                </div>
                
                
              </div>            
        </div>
      </Transition>
     
      

    </div>
    
</template>

<script>
import confetti from "canvas-confetti";
import Globe from 'globe.gl';
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {      
      current: undefined,
      appendNew: false,
      globe: undefined,
      globedata:[],
      count:{
        min:0,
        max:10000000,
      },
      items:[
          {
            id: 4535989082,
            title:'Ferrari F80',
            image:'https://www.ferrarimoscow.ru/ferrarimoscow/wp-content/uploads/2020/04/4.png',
            created: 1718904242128,
            lat: -176.1926,
            long: 51.8306,
            usd: 450000,
            stima: 450000,            
        },
      ],
    }
  },
  mounted(){
    
    this.createGlobe()
    

  },

  methods:{

    createGlobe(){
      const markerSvg = `<svg viewBox="-4 0 36 36">
        <path fill="currentColor" d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z"></path>
        <circle fill="black" cx="14" cy="14" r="7"></circle>
      </svg>`;

      // Gen random data
      const N = 30;
      this.globedata = [...Array(N).keys()].map(() => ({
        lat: (Math.random() - 0.5) * 180,
        lng: (Math.random() - 0.5) * 360,
        size: 7 + Math.random() * 30,
        pop: Math.round( Math.random() * 3000000),
        // color: ['red', 'white', 'blue', 'green'][Math.round(Math.random() * 3)]
        color: ['#FF59A9']
      }));

      this.count.max = Math.max(...this.globedata.map(o => o.pop))
      this.count.min = Math.min(...this.globedata.map(o => o.pop))
      console.log('min',this.count.min)
      console.log('max',this.count.max)
      
      

      // const weightColor = this.dd3.scaleSequential(this.dd3.interpolateYlOrRd).domain([0, 1e7]);
      // const weightColor = d3.scaleSequential().interpolator(d3.interpolateBlue).domain([0, 1e7]);
      const weightColor = (d) => {

        // console.log('weightColor',d)
        return 'purple'
      }

      let globe = Globe()
      globe        
        .globeImageUrl('//unpkg.com/three-globe/example/img/earth-night.jpg')
        .bumpImageUrl('//unpkg.com/three-globe/example/img/earth-topology.png')
        .backgroundImageUrl('//unpkg.com/three-globe/example/img/night-sky.png')
        .hexBinPointsData(this.globedata)        
        // .htmlTransitionDuration(2000)
        .pointOfView({ lat: 0, lng:0, altitude:1.1 }, [1000])
        .hexBinPointWeight('pop')
        .hexAltitude(d => d.sumWeight * 6e-8)
        .hexBinResolution(4)
        .hexTopColor(d => weightColor(d.sumWeight))
        .hexSideColor(d => weightColor(d.sumWeight))
        .hexBinMerge(true)
        .enablePointerInteraction(false)(document.getElementById('globeViz'));

        globe.controls().autoRotate = true;
        globe.controls().autoRotateSpeed = 0.3;
        globe.controls().enable = false
        this.globe = globe

    },
    addnew(){

      let newasset = {
            id: 4535989082,
            title:'Ferrari F80',
            image:'https://www.ferrarimoscow.ru/ferrarimoscow/wp-content/uploads/2020/04/4.png',
            created: 1718904242128,
            lat: -176.1926,
            long: 51.8306,
            usd: 450000,
            stima: 450000,            
        }
      this.items.push(newasset)

      let newpoint = {
        lat: (Math.random() - 0.5) * 180,
        lng: (Math.random() - 0.5) * 360,
        size: 7 + Math.random() * 30,    
        color: ['#FF59A9']
      }

      this.globedata.push(newpoint)      
      this.globe.htmlElementsData(this.globedata)

      this.previewNew(newasset)
      
      
    },
    
    
    previewNew(p){
      this.explode()
      this.current = p
      this.appendNew = true
      setTimeout(()=>{this.appendNew = false}, 3000)
      

      
    },


    explode(){
           confetti({
            particleCount: 350,
              spread: 200,
              gravity:.2,
              scalar:1,
          });
        },


  },
  });


</script>

<style scoped>
  .logos{
    position: fixed;
    left: 60px;
    top: 40px;
    z-index:1000;
  }

  .popupAsset{
    position: absolute;
    left: 50%;transform: translateX(-50%);
    /* width: fit-content; */
    /* height: fit-content; */
    -webkit-backdrop-filter: saturate(180%) blur(20px);backdrop-filter: saturate(180%) blur(20px);
    z-index: 100;
  }


  .slider{
    position: absolute; 
    z-index: 100; 
    right: 50px; 
    top: 0px; 
    width: 400px;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0);
    padding: 20px;
    border-radius: 20px;
    z-index: 100;
  }

  .sliderbox{
    /* width: 30vw; */
    height: 100vh;
    position: relative;
    overflow-y: scroll;
  }

  .card{
    position: relative;

  }

  /* will */
.will-enter-active {animation: will .5s ease-out}
.will-leave-active {animation: will .5s ease-out reverse}
@keyframes will {
    0% {opacity: .1; transform: translateX(0);}    
    100% {opacity: 1; transform: translateX(1);}
}

/* will */
.fader-enter-active {animation: fader 1.5s ease-out}
.fader-leave-active {animation: fader 1.5s ease-out reverse}
@keyframes fader {
    0% {opacity: .0; }    
    100% {opacity: 1;}
}
</style>